var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[(this.$route.name == 'add-gift-company')?[_c('PageHeader',{attrs:{"title":_vm.title1,"items":_vm.items}})]:[_c('PageHeader',{attrs:{"title":_vm.title2,"items":_vm.items}})],_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('b-form',{ref:"email",staticClass:"row",attrs:{"enctype":"multipart/form-data"}},[_c('b-form-group',{staticClass:"col-6",attrs:{"id":"input-group-1","label-for":"input-1"}},[_c('label',[_vm._v("Name "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('b-form-input',{class:{
                  'is-invalid': _vm.submitted && _vm.$v.form.name.$error,
                },attrs:{"id":"input-1","placeholder":"Enter Name"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),(_vm.submitted && !_vm.$v.form.name.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Name is required. ")]):_vm._e()],1),_c('b-form-group',{staticClass:"col-6",attrs:{"id":"input-group-4","label-for":"input-4"}},[_c('label',[_vm._v("Code "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('b-form-input',{class:{
                  'is-invalid': _vm.submitted && _vm.$v.form.code.$error,
                },attrs:{"id":"input-4","placeholder":"Enter Code"},model:{value:(_vm.form.code),callback:function ($$v) {_vm.$set(_vm.form, "code", $$v)},expression:"form.code"}}),(_vm.submitted && !_vm.$v.form.code.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Code is required. ")]):_vm._e()],1),_c('b-form-group',{staticClass:"col-12"},[_c('label',{attrs:{"for":"input-multi"}},[_vm._v("Description "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('b-form-textarea',{class:{
                  'is-invalid': _vm.submitted && _vm.$v.form.description.$error,
                },attrs:{"id":"article_header","placeholder":"Enter Description","rows":"4"},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}}),(_vm.submitted && !_vm.$v.form.code.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Description is required. ")]):_vm._e()],1),_c('b-form-group',{staticClass:"col-12",attrs:{"id":"input-group-7","label-for":"input-7"}},[_c('b-form-checkbox',{attrs:{"id":"input-7"},on:{"input":_vm.changestatus},model:{value:(_vm.form.is_active),callback:function ($$v) {_vm.$set(_vm.form, "is_active", $$v)},expression:"form.is_active"}},[_vm._v("Status")])],1),_c('div',{staticClass:"col-12"},[_c('b-button',{staticClass:"mr-2",attrs:{"type":"submit","variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.submitData.apply(null, arguments)}}},[(this.$route.name == 'add-gift-company')?_c('span',[_vm._v("Save Data")]):_c('span',[_vm._v("Update Data")])])],1)],1)],1)])])])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }