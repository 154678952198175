<template lang="">
  <Layout>
    <template v-if="this.$route.name == 'add-gift-company'">
      <PageHeader :title="title1" :items="items"></PageHeader>
    </template>
    <template v-else>
      <PageHeader :title="title2" :items="items"></PageHeader>
    </template>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <b-form enctype="multipart/form-data" ref="email" class="row">
              <b-form-group
                id="input-group-1"
                label-for="input-1"
                class="col-6"
              >
                <label>Name <span style="color: red">*</span></label>
                <b-form-input
                  id="input-1"
                  placeholder="Enter Name"
                  v-model="form.name"
                  :class="{
                    'is-invalid': submitted && $v.form.name.$error,
                  }"
                ></b-form-input>
                <div
                  v-if="submitted && !$v.form.name.required"
                  class="invalid-feedback"
                >
                  Name is required.
                </div>
              </b-form-group>
              <b-form-group
                id="input-group-4"
                label-for="input-4"
                class="col-6"
              >
                <label>Code <span style="color: red">*</span></label>
                <b-form-input
                  id="input-4"
                  placeholder="Enter Code"
                  v-model="form.code"
                  :class="{
                    'is-invalid': submitted && $v.form.code.$error,
                  }"
                ></b-form-input>
                <div
                  v-if="submitted && !$v.form.code.required"
                  class="invalid-feedback"
                >
                  Code is required.
                </div>
              </b-form-group>
              <b-form-group class="col-12">
                <label for="input-multi"
                  >Description <span style="color: red">*</span></label
                >
                <b-form-textarea
                  id="article_header"
                  v-model="form.description"
                  placeholder="Enter Description"
                  rows="4"
                  :class="{
                    'is-invalid': submitted && $v.form.description.$error,
                  }"
                >
                </b-form-textarea>
                <div
                  v-if="submitted && !$v.form.code.required"
                  class="invalid-feedback"
                >
                  Description is required.
                </div>
              </b-form-group>
              <b-form-group
                id="input-group-7"
                label-for="input-7"
                class="col-12"
              >
                <b-form-checkbox
                  id="input-7"
                  @input="changestatus"
                  v-model="form.is_active"
                  >Status</b-form-checkbox
                >
              </b-form-group>
              <div class="col-12">
                <b-button
                  type="submit"
                  variant="primary"
                  class="mr-2"
                  @click.prevent="submitData"
                  ><span v-if="this.$route.name == 'add-gift-company'"
                    >Save Data</span
                  >
                  <span v-else>Update Data</span>
                </b-button>
              </div>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import MixinRequest from "../../../../mixins/request";
import gatwayMixin from "../../../../mixins/ModuleJs/gift-company";
import { required } from "vuelidate/lib/validators";

export default {
  mixins: [MixinRequest, gatwayMixin],
  data() {
    return {
      title1: "ADD GIFT COMPANY",
      title2: "EDIT GIFT COMPANY",
      items: [
        {
          text: "Back",
          href: "/gift-company",
        },
        {
          text: "Data",
        },
      ],
    };
  },
  components: {
    Layout,
    PageHeader,
  },
  validations: {
    form: {
      name: { required },
      description: { required },
      code: { required },
    },
  },
};
</script>
